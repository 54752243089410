import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { EventEmitterTypes } from 'types/event-emitter.type';
import { useEventEmitterListener } from 'hooks/useEventEmitterListener';
import Icon from 'components/ui-components-v2/Icon';
import { SceneHelpers } from 'helpers/scene.helpers';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { FrameActions } from '..';
import { ICreativeOverview } from '../types/creative-overview.type';
import CreativeOverviewFramesBar from './frames-bar';
import CreativeOverviewProgressBar from './progress-bar';
import '../styles/play-bar.scss';

interface ComponentStoreProps {
    playing: ICreativeOverview['playing'];
    duration: ICreativeOverview['duration'];
    enrichedCreatives: ICreativeOverview['enrichedCreatives'];
}

interface Props {
    editable: boolean;
    frameActions: FrameActions;
    autoplayOnFirstOpen: boolean;
}

const CreativeOverviewPlaybar: React.FC<Props> = ({ editable, frameActions, autoplayOnFirstOpen }) => {
    const { playing, duration, enrichedCreatives } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            playing: 'playing',
            duration: 'duration',
            enrichedCreatives: 'enrichedCreatives'
        }
    });

    useEffect(() => {
        if (!playing && autoplayOnFirstOpen) {
            if (['displayAd', 'displayAdDesigned'].includes(enrichedCreatives?.[0].data.templateType)) {
                setTimeout(() => {
                    handlePlayPauseToggle();
                }, 1000);
            }
        }
    }, []);

    // Toggle play/pause on space key press
    useEffect(() => {
        const handleSpaceKeyPress = (event: KeyboardEvent) => {
            if (event.key === ' ') {
                // Prevent play pause toggle if an input is focused
                const activeElement = document.activeElement;
                const isInputFocused = activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA');

                if (!isInputFocused) {
                    event.preventDefault();
                    handlePlayPauseToggle();
                }
            }
        };

        document.addEventListener('keydown', handleSpaceKeyPress);

        return () => {
            document.removeEventListener('keydown', handleSpaceKeyPress);
        };
    }, [playing]);

    const currentTime = useEventEmitterListener(EventEmitterTypes.CEcurrentTime) ?? 0;

    const handlePlayPauseToggle = () => {
        if (playing) {
            SceneHelpers.pauseScene();
        } else {
            SceneHelpers.playScene();
        }

        ComponentStore.setModel('CreativeOverview', 'playing', !playing);
        EventEmitterHelpers.sent(EventEmitterTypes.CEiframe, { action: { type: `${playing ? 'pause' : 'play'}` } });
    };

    const handleRestart = () => {
        SceneHelpers.playScene();
        ComponentStore.setModel('CreativeOverview', 'playing', true);
        EventEmitterHelpers.sent(EventEmitterTypes.CEcurrentTime, 0);
        EventEmitterHelpers.sent(EventEmitterTypes.CEiframe, { action: { type: 'seek', seekTime: 0, playAfterSeek: true } });
    };

    const isOnEnd = () => {
        return currentTime > 0 && currentTime === duration;
    };

    const getFormattedTime = (type: 'duration' | 'currentTime') => {
        const time = (type === 'duration' ? duration : currentTime) || 0;
        const prefix = time < 10 ? '0:0' : '0:';
        return `${prefix}${Math.floor(time)}`;
    };

    // Show the slider for dynamicVideoDesigned templates
    // Refactor to no have this if statement
    const showSlider = () => {
        if (enrichedCreatives?.length === 1) {
            const enrichedCreative = enrichedCreatives[0];
            if (enrichedCreative.data.templateType === 'dynamicVideoDesigned' || enrichedCreative.data.templateType === 'dynamicVideo') {
                return true;
            }
        }

        return false;
    };

    return (
        <div className="creative-overview-play-bar">
            <div className="creative-overview-play-bar__play-pause">
                {isOnEnd() && (
                    <IconButton onClick={handleRestart} color="primary" size="large">
                        <Icon className="creative-overview-play-bar__play-pause__icon-replay">replay</Icon>
                    </IconButton>
                )}
                {!isOnEnd() && (
                    <Tooltip title="Use space to play/pause" placement="top" enterDelay={400}>
                        <IconButton onClick={handlePlayPauseToggle} color="primary" size="large">
                            <Icon className="creative-overview-play-bar__play-pause__icon">{playing ? 'pause_circle_contained' : 'play_circle_contained'}</Icon>
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {showSlider() ? (
                <CreativeOverviewProgressBar slider />
            ) : (
                <div className="creative-overview-play-bar__frames-bar">
                    <CreativeOverviewFramesBar editable={editable} frameActions={frameActions} />
                </div>
            )}
            <div className="creative-overview-play-bar__progress">{`${getFormattedTime('currentTime')} / ${getFormattedTime('duration')}`}</div>
        </div>
    );
};

export default CreativeOverviewPlaybar;
