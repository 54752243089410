import React, { useMemo, useState } from 'react';
import Comments from 'components/social/Comments';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';

interface ComponentStoreProps {
    creative: CreativeEditorV2['creative'];
}

const SidebarComments = () => {
    const { creative } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            creative: 'creative'
        }
    });
    const [selectedCategoryKey, setSelectedCategoryKey] = useState<string>('all');

    const entity = (() => {
        const baseEntity = 'creativeEditor-';

        return `${baseEntity}${creative.id}`;
    })();

    const categories = useMemo(() => {
        const categories = { all: 'All' };

        const template = TemplateManager.getTemplateByIdentifier(creative.data.templateId) as TDTemplateAsset;

        template?.data.templateSetup?.formats.forEach((format) => {
            categories[format.key] = format.title;
        });

        return categories;
    }, [creative.data]);
    // TODO check dependency array above

    return (
        <Comments
            entity={entity}
            categoryKey={selectedCategoryKey}
            categories={categories}
            onChangeCategory={(categoryKey) => setSelectedCategoryKey(categoryKey)}
        />
    );
};

export { SidebarComments };
