import React, { ReactNode, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { ListItemIcon, ListItemText } from '@mui/material';
import { InterfaceSetupExport } from 'types/interfaceSetupExport.type';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';
import EditorData from 'components/editor-data/EditorData';
import CreativeModelsHelpers from 'components/creatives-v2/helpers/creative-models.helpers';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import CreativeEditorHelpers from '../helpers/creative-editor.helpers';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';

import '../styles/creative-editor-format-actions.scss';
import Tooltip from 'components/ui-components-v2/Tooltip';

interface ComponentStoreProps {
    creative: CreativeEditorV2['creative'];
    localScopeId: CreativeEditorV2['localScopeId'];
    activeFrame: CreativeEditorV2['activeFrame'];
    selectedFormats: CreativeEditorV2['selectedFormats'];
}

interface Props {
    formatKey?: string;
}

const CreativeEditorV2FormatActions = ({ formatKey }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { creative, localScopeId, activeFrame, selectedFormats } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            creative: 'creative',
            localScopeId: 'localScopeId',
            activeFrame: 'activeFrame',
            selectedFormats: 'selectedFormats'
        }
    });

    const creativeInstance = useMemo(() => {
        if (!activeFrame || !formatKey) return null;
        return getCreativeInstance(creative);
    }, [creative, activeFrame, formatKey]);

    const creativeModel = creativeInstance?.getCreativeModel(activeFrame || '') || '';

    const hasOverwrite = useMemo(() => {
        if (!creativeInstance || !formatKey) return false;
        return !!creativeInstance.getFormatOverwrites(formatKey, creativeModel);
    }, [creative?.data, formatKey, creativeInstance, creativeModel]);

    if (!activeFrame || !formatKey) return null;

    const resetAllOverwrites = () => {
        if (!hasOverwrite) return;

        const overwrites = EditorData.getValueFromModel(
            CreativeModelsHelpers.getModelPath(true, true, creativeModel, formatKey),
            undefined,
            `scope-${localScopeId}`
        );

        // We want to reset all the overwrites, so in most cases we will just set the new value to undefined. However, if a format is
        // selected, its blockmodel gets a prefix with '[format].overwrites' and a standard { type: 'main' }. If we do now keep that temporary
        // type value when formats are selected, the multiInput will fail its validation and the inputs will disappear.
        const newValue = selectedFormats.length && overwrites && overwrites.type ? { type: overwrites.type } : undefined;

        EditorData.setModel(CreativeModelsHelpers.getModelPath(true, true, creativeModel, formatKey), newValue, [], `scope-${localScopeId}`);

        closeOverwriteOptions();
    };

    const resetSingleOverwrite = (inputKey: string) => {
        if (!hasOverwrite) return;
        CreativeEditorHelpers.resetSingleOverwrite(formatKey, creativeModel, inputKey, localScopeId);
        closeOverwriteOptions();
    };

    const openOverwriteOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeOverwriteOptions = () => {
        setAnchorEl(null);
    };

    // Create a list of menu items. By default it has the 'reset all overwrites' option, but it checks
    // for single overwrites as well and adds them to the list
    const getMenuItems = () => {
        if (!hasOverwrite || !creativeInstance) return null;

        const formatOverwrites = creativeInstance.getFormatOverwrites(formatKey, creativeModel);

        if (!formatOverwrites) return null;

        // Add the default 'reset all overwrites' option
        const menuItems: ReactNode[] = [
            <MenuItem key="all" onClick={resetAllOverwrites}>
                <ListItemIcon>
                    <Icon>history</Icon>
                </ListItemIcon>
                <ListItemText>{Translation.get('actions.resetAllOverwrites', 'common')}</ListItemText>
            </MenuItem>
        ];

        const interfaceSetup = (TemplateManager.getTemplateByIdentifier(creative.data.templateId) as TDTemplateAsset)?.data?.interfaceSetup;

        // Get all the nested items in one array
        const flattenInterfaceSetupItems = (items: any[]): any[] => {
            return items.reduce((acc, item) => {
                if (item.items) {
                    return acc.concat(flattenInterfaceSetupItems(item.items));
                }
                return acc.concat(item);
            }, []);
        };

        const interfaceSetupItems = flattenInterfaceSetupItems(interfaceSetup || []);

        // Add the single overwrite options
        Object.keys(formatOverwrites).forEach((key) => {
            if (key === 'type') return;

            // Get the item from the interface setup to get access to the label
            const item = interfaceSetupItems.find((item) => item.model?.startsWith(`${key}.${Object.keys(formatOverwrites[key])[0]}`));
            if (!item) return;

            menuItems.push(
                <MenuItem key={key} onClick={() => resetSingleOverwrite(key)}>
                    <ListItemIcon>
                        <Icon>history</Icon>
                    </ListItemIcon>
                    <ListItemText>
                        {Translation.get('actions.reset', 'common')} {item.label?.toLowerCase()}
                    </ListItemText>
                </MenuItem>
            );
        });

        return menuItems;
    };

    return (
        <div className="creative-editor-format-actions">
            {hasOverwrite && (
                <Tooltip title={Translation.get('labels.formatHasOverwrites')} placement="top">
                    <IconButton size="small" onClick={openOverwriteOptions}>
                        <Icon className="creative-editor-format-actions__overwrite">history</Icon>
                    </IconButton>
                </Tooltip>
            )}
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeOverwriteOptions}>
                {getMenuItems()}
            </Menu>
        </div>
    );
};

export { CreativeEditorV2FormatActions };
