import React, { useMemo } from 'react';
import { IconButton } from '@mui/material';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Translation from 'components/data/Translation';
import Chip from 'components/ui-components-v2/Chip';
import Icon from 'components/ui-components-v2/Icon';
import cloneDeep from 'helpers/cloneDeep';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';

import { CreativeV2EditorEdit } from './sidebar-edit';
import '../styles/sidebar-interface.scss';

interface ComponentStoreProps {
    selectedFormats: CreativeEditorV2['selectedFormats'];
    formatsCount: CreativeEditorV2['formatsCount'];
    creativeTemplateId: CreativeEditorV2['creative']['data']['templateId'];
}

/**
 * This is the base component of the creative builder right sidebar.
 * This component handles the overwrite formats component and loads the creative editor edit component in
 */
const SidebarInterface = () => {
    const { selectedFormats, formatsCount, creativeTemplateId } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            selectedFormats: 'selectedFormats',
            formatsCount: 'formatsCount',
            creativeTemplateId: 'creative.data.templateId'
        }
    });

    const hasSelectedFormats = !!selectedFormats.length;

    const clearSelectedFormats = () => {
        ComponentStoreHelpers.setModel('CreativeEditorV2', 'selectedFormats', []);
    };

    /**
     * Deselects a format
     * @param formatKey The key of the format that we want to deselect
     */
    const deselectFormat = (formatKey: string) => {
        const clonedSelectedFormats = cloneDeep(selectedFormats);
        if (!clonedSelectedFormats.includes(formatKey)) return;

        clonedSelectedFormats.splice(clonedSelectedFormats.indexOf(formatKey), 1);
        ComponentStoreHelpers.setModel('CreativeEditorV2', 'selectedFormats', clonedSelectedFormats);
    };

    const formatList = useMemo(() => {
        const formats = CreativeV2FormatHelpers.getFormats(creativeTemplateId);
        return formats || [];
    }, [creativeTemplateId]);

    return (
        <div className="sidebar-interface">
            {hasSelectedFormats && (
                <div className="sidebar-interface__overwrites">
                    <div className="sidebar-interface__overwrites__header">
                        <div className="sidebar-interface__overwrites__header__title">
                            <Icon>info</Icon>
                            <div>{Translation.get('titles.overwriteFormats', 'creatives-v2')}</div>
                        </div>
                        <IconButton className="sidebar-interface__overwrites__header__close" size="small" onClick={() => clearSelectedFormats()}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                    <div className="sidebar-interface__overwrites__content">
                        <div className="sidebar-interface__overwrites__content__title">
                            {Translation.get('titles.editingFormats', 'creatives-v2', {
                                selectedFormats: selectedFormats.length,
                                formatsCount: formatsCount || 0
                            })}
                        </div>
                        <div className="sidebar-interface__overwrites__content__chips">
                            {selectedFormats.map((format) => (
                                <Chip
                                    className="sidebar-interface__overwrites__content__chips__chip"
                                    size="small"
                                    key={format}
                                    label={formatList.find((f) => f.key === format)?.title || format}
                                    onDelete={() => deselectFormat(format)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <div className="sidebar-interface__edit">
                <CreativeV2EditorEdit />
            </div>
        </div>
    );
};

export { SidebarInterface };
