import EditorData from 'components/editor-data/EditorData';
import cloneDeep from 'helpers/cloneDeep';
import CreativeModelsHelpers from 'components/creatives-v2/helpers/creative-models.helpers';

class CreativeEditorHelpers {
    /**
     * Reset the overwrites for a single input
     * @param formatKey the key of the format
     * @param creativeModel the model that this type of creative uses (e.g. frames.frame1 for displayAds)
     * @param inputKey the key of the input
     * @param localScopeId the id of the local scope
     */
    static resetSingleOverwrite = (formatKey: string, creativeModel: string, inputKey: string, localScopeId: string): void => {
        const overwrites = cloneDeep(
            EditorData.getValueFromModel(CreativeModelsHelpers.getModelPath(true, true, creativeModel, formatKey), undefined, `scope-${localScopeId}`)
        );

        if (!overwrites) return;

        // Remove this inputkey from the overwrites object
        const newValue = Object.keys(overwrites).reduce((acc, key) => {
            if (key !== inputKey) {
                acc[key] = overwrites[key];
            }
            return acc;
        }, {});

        EditorData.setModel(CreativeModelsHelpers.getModelPath(true, true, creativeModel, formatKey), newValue, [], `scope-${localScopeId}`);
    };
}

export default CreativeEditorHelpers;
