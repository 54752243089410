import React, { useEffect, useState } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import { isCreativeEnriched, isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { isTemplateDesignerTemplateType } from 'components/template-management/utilities';
import { ICreativeOverview } from '../types/creative-overview.type';
import FormatsList, { Item } from '../../formats-list';

import '../styles/single-view-side-bar.scss';

interface ComponentStoreProps {
    enrichedCreatives: ICreativeOverview['enrichedCreatives'];
}

const SingleViewSideBar: React.FC = () => {
    const [items, setItems] = useState<Item[]>([]);

    const { enrichedCreatives } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            enrichedCreatives: 'enrichedCreatives'
        }
    });

    useEffect(() => {
        mapItems();
    }, []);

    const mapItems = () => {
        if (!enrichedCreatives) return;

        const items: Item[] = enrichedCreatives.flatMap((creative) => {
            if (isTemplateCreative(creative)) {
                const formats = CreativeV2FormatHelpers.getFormats(creative.data.templateId) || [];

                if (isTemplateDesignerTemplateType(creative.data.templateType)) {
                    // filter formats based on active formats
                    const activeFormatsKeys = creative.data.settings?.activeFormats || [];
                    return formats.filter((format) => activeFormatsKeys.includes(format.key));
                } else {
                    return formats;
                }
            } else if (isCreativeEnriched(creative)) {
                return [
                    {
                        key: creative.id,
                        title: creative.title
                    }
                ];
            }

            return [];
        });

        setItems(items);
    };

    const handleClickItem = (item: Item) => {
        ComponentStore.setModel('CreativeOverview', 'singleViewKey', item.key);
    };

    const getFilters = (): string[] | undefined => {
        if (enrichedCreatives?.length !== 1 || !isTemplateCreative(enrichedCreatives[0])) return;

        const formatSets = (TemplateManager.getTemplateByIdentifier(enrichedCreatives[0]?.data?.templateId) as TDTemplateAsset)?.data.settings?.formatSets;

        if (!formatSets || !Array.isArray(formatSets)) return;

        return formatSets.map((format) => format.title).filter((set) => set !== 'Unset');
    };

    return (
        <div className="single-view-side-bar">
            <FormatsList items={items} compact selectable={false} filters={getFilters()} onClickItem={handleClickItem} />
        </div>
    );
};

export default SingleViewSideBar;
