import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Translation from 'components/data/Translation';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Dialog from 'components/ui-components/Dialog';
import ComponentStore from 'components/data/ComponentStore';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { CreativeEditorV2 as CSCreativeEditorV2, DisabledFeature } from '../types/creativeEditorV2.type';
import { CreativeV2EditorHeader } from './header';
import Sidebar from './sidebar';
import { CreativeEditorV2Content } from './content';
import { CreativeV2Template, CreativeV2TemplateEnriched } from '../types/creativeV2.type';
import '../styles/main.scss';

interface ComponentStoreProps {
    creativeType: CSCreativeEditorV2['creative']['type'];
    creativeTemplateId: CSCreativeEditorV2['creative']['data']['templateId'];
    templateLoaded: CSCreativeEditorV2['templateLoaded'];
}

interface Props {
    creativeV2Template?: CreativeV2Template;
    externalData?: any;
    disabledFeatures?: DisabledFeature[];
    onSaveCreative?: (creative: CreativeV2TemplateEnriched) => void;
    onClose?: () => void;
}

const CreativeEditorV2: React.FC<Props> = ({ creativeV2Template, externalData, disabledFeatures = ['languages'], onClose, onSaveCreative }) => {
    const { creativeType, creativeTemplateId, templateLoaded } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            creativeType: 'creative.type',
            creativeTemplateId: 'creative.data.templateId',
            templateLoaded: 'templateLoaded'
        }
    });

    useEffect(() => {
        return () => {
            ComponentStore.remove('CreativeEditorV2');
        };
    }, []);

    useEffect(() => {
        setInitialData();
    }, [creativeV2Template?.assetManagerId, creativeV2Template?.type]);

    /**
     * The creative editor receives a simple creative object from redux
     * To be able to use edit the creative we need to enrich the creative based on the type it will enrich the creative with the correct data
     * If the creative is a creative with a template linked it will enrich the creative with the template data
     * @returns
     */
    const setInitialData = async () => {
        if (externalData?.templateData) TemplateManager.addTemplate(externalData.templateData);

        const getEnrichedCreative = async () => {
            if (externalData?.creativeData) {
                return {
                    type: 'template',
                    data: externalData.creativeData
                } as CreativeV2TemplateEnriched;
            }

            if (!creativeV2Template) return null;

            const creative = (await CreativeV2Helpers.enrichCreative(creativeV2Template)) as CreativeV2TemplateEnriched;
            return creative;
        };

        const enrichedCreative = await getEnrichedCreative();

        if (!enrichedCreative) return;

        const formats = CreativeV2FormatHelpers.getFormats(enrichedCreative?.data?.templateId);

        ComponentStore.setData('CreativeEditorV2', {
            creativeView: 'canvasFixed',
            sidebarView: 'interface',
            selectedFormats: [],
            localScopeId: uuidv4(),
            formatsCount: formats?.length || 1,
            templateLoaded: true,
            creative: enrichedCreative,
            activeFrame: 'frame1',
            disabledFeatures
        });
    };

    const getStatus = (): 'noCreative' | 'loadingCreative' | 'noTemplate' | 'templateFound' => {
        if (!creativeV2Template && !creativeType) {
            return 'noCreative';
        }

        if (!templateLoaded) {
            return 'loadingCreative';
        }

        if (!TemplateManager.templateExists(creativeTemplateId)) {
            return 'noTemplate';
        }

        return 'templateFound';
    };

    const handleCloseEditor = () => {
        onClose && onClose();
    };

    // TODO: CREATIVES-v2: IMPLEMENT NEW DESIGN FOR LOADERS OR ERROR MESSAGES
    return (
        <Dialog open fullScreen>
            {getStatus() === 'noCreative' && <div>{Translation.get('statuses.noCreativeFound', 'creatives-v2')}</div>}
            {getStatus() === 'loadingCreative' && (
                <div className="creative-editor-v2__loading-creative">
                    <CircularProgress />
                </div>
            )}
            {getStatus() === 'noTemplate' && <div>{Translation.get('statuses.noTemplateFound', 'creatives-v2')}</div>}
            {getStatus() === 'templateFound' && (
                <div className="creative-editor-v2">
                    <div className="creative-editor-v2__header">
                        <CreativeV2EditorHeader onSaveCreative={onSaveCreative} closeEditor={handleCloseEditor} />
                    </div>
                    <div className="creative-editor-v2__main">
                        {!templateLoaded && (
                            <div className="creative-editor-v2__loading-template">
                                <CircularProgress />
                            </div>
                        )}
                        {templateLoaded && (
                            <>
                                <div className="creative-editor-v2__content">
                                    <CreativeEditorV2Content />
                                </div>
                                <div className="creative-editor-v2__sidebar">
                                    <Sidebar />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </Dialog>
    );
};

export default CreativeEditorV2;
