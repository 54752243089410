import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';
import { SidebarInterface } from './sidebar-interface';
import { SidebarComments } from './sidebar-comments';
import '../styles/sidebar.scss';

interface ComponentStoreProps {
    sidebarView: CreativeEditorV2['sidebarView'];
}

const BricksCreativeEditorSidebar: React.FC = () => {
    const { sidebarView } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            sidebarView: 'sidebarView'
        }
    });

    return (
        <div className="creative-editor-v2-sidebar">
            {sidebarView === 'interface' && <SidebarInterface />}
            {sidebarView === 'comments' && <SidebarComments />}
        </div>
    );
};

export default BricksCreativeEditorSidebar;
