import React, { useEffect, useRef, useMemo } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import cloneDeep from 'helpers/cloneDeep';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import EditorData from 'components/editor-data/EditorData';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import { FRAME_PREVIEWS } from 'components/creatives-v2/constants';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';
import { FramesBarFrame } from '../../creative-overview/components/frame';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';
import CreativeOverview from '../../creative-overview';
import { CreativeEditorV2FormatHeader } from './creative-editor-format-header';
import { BASE_BLOCK_MODEL } from '../helpers/creative-editor-edit.helpers';

interface ComponentStoreProps {
    creative: CreativeEditorV2['creative'];
    activeFrame: CreativeEditorV2['activeFrame'];
    localScopeId: CreativeEditorV2['localScopeId'];
    disabledFeatures: CreativeEditorV2['disabledFeatures'];
}

const CreativeEditorV2Content: React.FC = () => {
    const { creative, activeFrame, localScopeId, disabledFeatures } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            creative: 'creative',
            activeFrame: 'activeFrame',
            localScopeId: 'localScopeId',
            disabledFeatures: 'disabledFeatures'
        }
    });

    // We use activeFrame as a ref to get the current active frame in the screenshot callback event listener
    const activeFrameRef = useRef(activeFrame);
    // The creative needs a ref as well, because e.g. the changeFrameOrder prop function needs the current creative data
    const creativeRef = useRef(creative);

    useEffect(() => {
        activeFrameRef.current = activeFrame;
    }, [activeFrame]);

    useEffect(() => {
        creativeRef.current = creative;
    }, [creative]);

    const isCreativeMultiFrame = useMemo(() => getCreativeInstance(creative).isMultiFrame(), [creative]);

    // Listen to messages from the iframe for getting a screenshot of the updated frame
    useEffect(() => {
        window.addEventListener('message', handleScreenshot);

        return () => {
            window.removeEventListener('message', handleScreenshot);
        };
    }, []);

    const handleScreenshot = (event?: MessageEvent) => {
        const newActiveFrame = activeFrameRef.current;

        if (event?.data.base64 && newActiveFrame) {
            const base64image = event.data.base64;
            ComponentStore.setModel('CreativeEditorV2', `creative.${FRAME_PREVIEWS}.${newActiveFrame}`, base64image);
        }
    };

    const addFrame = () => {
        const creative = creativeRef.current;
        const template = TemplateManager.getTemplateByIdentifier(creative.data.templateId) as TDTemplateAsset;

        let templateInput = cloneDeep(creative?.data?.templateInput);

        // TODO always [1]?
        if (!templateInput || !Object.keys(templateInput).length) {
            templateInput = {
                frames: {
                    frame1: {
                        type: template?.data?.templateSetup?.frameTypes[1].key
                    }
                }
            };
        }

        const frameCount = Object.keys(templateInput.frames).length;

        templateInput.frames[`frame${frameCount + 1}`] = {
            type: template?.data?.templateSetup?.frameTypes[1].key
        };

        EditorData.setModel(BASE_BLOCK_MODEL, templateInput, [], `scope-${localScopeId}`);
    };

    const deleteFrame = (frame: FramesBarFrame) => {
        const creative = creativeRef.current;
        const template = TemplateManager.getTemplateByIdentifier(creative.data.templateId) as TDTemplateAsset;

        let { templateInput } = cloneDeep(creative?.data) || {};
        const { framePreviews } = cloneDeep(creative?.data) || {};

        if (!templateInput)
            templateInput = {
                frames: {
                    frame1: {
                        type: template?.data?.templateSetup?.frameTypes[1].key
                    }
                }
            };

        delete templateInput.frames[frame.id];

        const newFrames = {};
        const newFramePreviews = {};

        // rename frames id to the correct order
        // if someones deletes frame 2 then frame 3 should be renamed to frame 2
        Object.keys(templateInput.frames)
            .sort((a, b) => {
                const numA = parseInt(a.replace('frame', ''));
                const numB = parseInt(b.replace('frame', ''));
                return numA - numB;
            })
            .forEach((frameKey, index) => {
                newFrames[`frame${index + 1}`] = templateInput.frames[frameKey];

                if (framePreviews) {
                    newFramePreviews[`frame${index + 1}`] = framePreviews[frameKey];
                }
            });

        templateInput.frames = newFrames;
        EditorData.setModel(BASE_BLOCK_MODEL, templateInput, [], `scope-${localScopeId}`);
        ComponentStore.setModel('CreativeEditorV2', `creative.${FRAME_PREVIEWS}`, newFramePreviews);
    };

    /**
     * In the onchange callback we get the new order of frames from the creative overview. We use this order to update the templateInput
     * for each frame accordingly.
     */
    const changeFrameOrder = (newFrames: FramesBarFrame[]) => {
        const creative = creativeRef.current;
        const { templateInput, framePreviews } = cloneDeep(creative?.data) || {};

        const newTemplateInput = {};
        const newFramePreviews = {};

        newFrames.forEach((newFrame, index) => {
            const id = newFrame.id;
            newTemplateInput[`frame${index + 1}`] = templateInput?.frames[id];

            if (framePreviews) {
                newFramePreviews[`frame${index + 1}`] = framePreviews[id];
            }
        });

        EditorData.setModel(BASE_BLOCK_MODEL + '.frames', newTemplateInput, [], `scope-${localScopeId}`);

        // Update the framePreviews in the creative
        ComponentStore.setModel('CreativeEditorV2', `creative.${FRAME_PREVIEWS}`, newFramePreviews);
    };

    const handleChangeActiveFrame = (frameId: FramesBarFrame['id']) => {
        if (activeFrame !== frameId) {
            ComponentStore.setModel('CreativeEditorV2', 'activeFrame', frameId);
        }
    };

    const getIframeOverwrites = (): IframeOverwrites<IframeData> => {
        if (!isCreativeMultiFrame) return {};

        let frameNrV2 = 1;

        // Template repo expects a active frame nr
        if (activeFrame) {
            frameNrV2 = parseInt(activeFrame.replace('frame', ''));
        }

        return { data: { frameNrV2 } };
    };

    return (
        <>
            <CreativeOverview
                editable
                creatives={[creative]}
                frameActions={{
                    onAddFrame: addFrame,
                    onDeleteFrame: deleteFrame,
                    onChangeFrameOrder: changeFrameOrder,
                    onChangeActiveFrame: handleChangeActiveFrame
                }}
                iframeOverwrites={getIframeOverwrites()}
                disabledFeatures={disabledFeatures}
                headerComponent={<CreativeEditorV2FormatHeader />}
            />
        </>
    );
};

export { CreativeEditorV2Content };
