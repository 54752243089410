import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { isCreativeEnriched, isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import { CreativeV2, CreativeV2Enriched, CreativeV2TemplateEnriched } from '../creative-editor/types/creativeV2.type';
import ZoomBar from './components/zoom-bar';
import { FramesBarFrame } from './components/frame';
import Canvas from './components/canvas';
import CreativeOverviewPlaybar from './components/play-bar';
import { CreativeViewType, ICreativeOverview } from './types/creative-overview.type';
import SingleViewSideBar from './components/single-view-side-bar';
import { DisabledFeature } from '../creative-editor/types/creativeEditorV2.type';
import './styles/main.scss';

const CreativeOverview: React.FC<Props> = ({
    creatives,
    editable = false,
    availableViews = ['canvasFixed', 'canvasFree'],
    showEditCreativeButton = false,
    frameActions = {},
    iframeOverwrites,
    headerComponent,
    autoplayOnFirstOpen = false,
    disabledFeatures = []
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { view, enrichedCreatives, activeFrame } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            view: 'view',
            activeFrame: 'activeFrame',
            enrichedCreatives: 'enrichedCreatives'
        }
    });

    // Set the initial ComponentStore values
    useEffect(() => {
        ComponentStore.setData('CreativeOverview', {
            view: 'canvasFixed',
            canvasZoom: 1,
            playing: false,
            duration: 0,
            sceneKey: uuidv4(),
            activeFrame: 'frame1',
            disabledFeatures
        } as ICreativeOverview);

        return () => {
            // Don't remove the ComponentStore every time the component unmounts, because in some cases
            // we want to keep the data in the store (e.g. creative tab -> open editor -> close editor)
            // We remove this component store if the slide panel closes
        };
    }, []);

    // Check if the creative is already enriched. If not, enrich it.
    // If the creative gets updated in the parent component (e.g. templateInput gets updated), update the enrichedCreative
    useEffect(() => {
        if (!creatives) return;

        if (editable && isSingleTemplateCreative(creatives)) {
            setEnrichedCreatives(creatives as CreativeV2TemplateEnriched[]);
        } else if (allCreativesAreEnriched(creatives)) {
            setEnrichedCreatives(creatives as CreativeV2Enriched[]);
        } else {
            enrichCreatives();
        }
    }, [creatives]);

    // Give back the active frame to the parent component
    useEffect(() => {
        if (frameActions.onChangeActiveFrame && activeFrame) frameActions.onChangeActiveFrame(activeFrame);
    }, [activeFrame]);

    // Check if all creatives are enriched. Return false if not
    const allCreativesAreEnriched = (creatives: CreativeV2[] | CreativeV2Enriched[]): boolean => {
        for (const creative of creatives) {
            if (!isCreativeEnriched(creative)) return false;
        }
        return true;
    };

    const isSingleTemplateCreative = (creatives: CreativeV2[] | CreativeV2Enriched[]): boolean => {
        if (!creatives) return false;
        return creatives.length === 1 && isCreativeEnriched(creatives[0]) && isTemplateCreative(creatives[0]);
    };

    const showPlayBar = () => {
        if (disabledFeatures.includes('playBar')) return false;
        if (!enrichedCreatives) return false;

        if (isSingleTemplateCreative(enrichedCreatives)) {
            return getCreativeInstance(enrichedCreatives[0])?.showPlayBar();
        }
        return false;
    };

    // Enrich all creatives
    const enrichCreatives = async () => {
        if (!creatives) return;

        const promises: Promise<CreativeV2Enriched>[] = [];

        for (const creative of creatives) {
            promises.push(CreativeV2Helpers.enrichCreative(creative));
        }

        const combinedEnrichedCreatives = await Promise.all(promises);

        setEnrichedCreatives(combinedEnrichedCreatives);
    };

    // Push the enriched creative to the ComponentStore
    const setEnrichedCreatives = (newEnrichedCreatives: CreativeV2Enriched[]) => {
        if (newEnrichedCreatives && !isEqual(newEnrichedCreatives, enrichedCreatives)) {
            ComponentStore.setModel('CreativeOverview', 'enrichedCreatives', newEnrichedCreatives);
        }

        if (loading) setLoading(false);
    };

    return (
        <div className="creative-overview">
            {loading && (
                <div className="creative-overview__loading">
                    <CircularProgress></CircularProgress>
                </div>
            )}
            {!loading && !enrichedCreatives?.length && <div className="creative-overview__empty">No formats found</div>}
            {!loading && !!enrichedCreatives && enrichedCreatives.length > 0 && (
                <>
                    {view === 'single' && (
                        <div className="creative-overview__single-view-side-bar">
                            <SingleViewSideBar />
                        </div>
                    )}
                    <div className="creative-overview__canvas-wrapper">
                        <div className="creative-overview__canvas-wrapper__zoom-bar">
                            <ZoomBar availableViews={availableViews} showEditCreativeButton={showEditCreativeButton} />
                        </div>
                        <div className="creative-overview__canvas-wrapper__canvas">
                            <Canvas iframeOverwrites={iframeOverwrites} headerComponent={headerComponent} editable={editable} />
                            {loading && (
                                <>
                                    <div className="creative-overview__canvas-wrapper__canvas__overlay"></div>
                                    <div className="creative-overview__canvas-wrapper__canvas__overlay-spinner">
                                        <CircularProgress></CircularProgress>
                                    </div>
                                </>
                            )}
                        </div>
                        {showPlayBar() && (
                            <div className="creative-overview__canvas-wrapper__play-bar">
                                <CreativeOverviewPlaybar editable={editable} frameActions={frameActions} autoplayOnFirstOpen={autoplayOnFirstOpen} />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default CreativeOverview;

export interface FrameActions {
    onChangeActiveFrame?: (frameId: FramesBarFrame['id']) => void;
    onAddFrame?: () => void;
    onDeleteFrame?: (frame: FramesBarFrame) => void;
    onChangeFrameOrder?: (newFrames: FramesBarFrame[]) => void;
}

interface Props {
    creatives: CreativeV2[] | CreativeV2Enriched[];
    editable?: boolean;
    availableViews?: CreativeViewType[];
    showEditCreativeButton?: boolean;
    iframeOverwrites?: IframeOverwrites<IframeData>;
    headerComponent?: React.ReactElement;
    frameActions?: FrameActions;
    disabledFeatures?: DisabledFeature[];
    autoplayOnFirstOpen?: boolean;
    handleSetCreative?: (creative: any) => void; // todo type
}

interface ComponentStoreProps {
    view: ICreativeOverview['view'];
    activeFrame: ICreativeOverview['activeFrame'];
    enrichedCreatives: ICreativeOverview['enrichedCreatives'];
}
