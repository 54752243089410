import React, { useRef, useEffect } from 'react';
import InfiniteViewer, { OnPinch } from 'react-infinite-viewer';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import { ICreativeOverview } from '../types/creative-overview.type';
import GridWrapper from '../../grid-wrapper';
import '../styles/canvas-free.scss';

interface ComponentStoreProps {
    view: ICreativeOverview['view'];
    canvasZoom: ICreativeOverview['canvasZoom'];
    enrichedCreatives: ICreativeOverview['enrichedCreatives'];
    singleViewKey: ICreativeOverview['singleViewKey'];
}

interface Props {
    iframeOverwrites?: IframeOverwrites<IframeData>;
    headerComponent?: React.ReactElement;
    editable?: boolean;
}

const CreativeOverviewCanvasFree = ({ iframeOverwrites, headerComponent, editable }: Props) => {
    const infiniteViewerRef = useRef<any>(null);

    const { view, canvasZoom, enrichedCreatives, singleViewKey } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            view: 'view',
            canvasZoom: 'canvasZoom',
            enrichedCreatives: 'enrichedCreatives',
            singleViewKey: 'singleViewKey'
        }
    });

    useEffect(() => {
        // if you load a single item or the view changes, scroll to top left
        if (infiniteViewerRef.current) {
            setTimeout(() => {
                infiniteViewerRef?.current?.scrollTo(0, 0);
            });
        }
    }, [singleViewKey, view]);

    /* When user uses pinch on mousepad to zoom in/out */
    const onPinch = (e: OnPinch) => {
        ComponentStore.setModel('CreativeOverview', 'canvasZoom', e.zoom);
    };

    if (!enrichedCreatives) return null;

    return (
        <InfiniteViewer
            ref={infiniteViewerRef}
            zoom={canvasZoom}
            className="creative-overview-canvas-free"
            zoomRange={[0.03, 10]}
            maxPinchWheel={10}
            onPinch={onPinch}
            displayHorizontalScroll={false}
            displayVerticalScroll={false}
            rangeX={[-200, Infinity]}
            rangeY={[-200, Infinity]}
            useAutoZoom>
            <div className="creative-overview-canvas-free__viewport">
                <GridWrapper
                    infiniteViewerRef={infiniteViewerRef}
                    view={view}
                    creatives={enrichedCreatives}
                    enableScaling={false}
                    zoom={canvasZoom}
                    iframeOverwrites={iframeOverwrites}
                    headerComponent={headerComponent}
                    singleViewKey={view === 'single' ? singleViewKey : undefined}
                    editable={editable}
                />
            </div>
        </InfiniteViewer>
    );
};

export default CreativeOverviewCanvasFree;
