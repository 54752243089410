import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import merge from 'lodash/merge';
import InterfaceSetupInput from 'types/InterfaceSetupInput.type';
import cloneDeep from 'helpers/cloneDeep';
import EditorData from 'components/editor-data/EditorData';
import { CreativeV2TemplateEnriched } from '../types/creativeV2.type';

export const BASE_BLOCK_MODEL = 'data';

class CreativeEditorEditHelpers {
    /**
     * Cleans up the overwrites data in the template input object. It will go over each key in the object and checks if it is the same.
     * If it is the same it will delete it from the overwrites object.
     */
    public static clearOverwriteData(localScopeId: string, creative: CreativeV2TemplateEnriched, selectedFormats: string[]): void {
        if (!creative.data?.templateInput) return;

        const templateInput = cloneDeep(creative.data.templateInput);
        const generalData = templateInput;

        // Copy and merge data for every element
        for (const format of selectedFormats) {
            const overwriteModel = 'overwrites.' + format;
            const overwriteData = get(generalData, overwriteModel);

            if (overwriteData) {
                let overwriteDataCloned = cloneDeep(overwriteData); // eslint-disable-line
                CreativeEditorEditHelpers.clearOverwriteDataCompareLoop(overwriteDataCloned, generalData);
                EditorData.setModel(BASE_BLOCK_MODEL + '.' + overwriteModel, overwriteDataCloned, [], `scope-${localScopeId}`);
            }
        }
    }

    /**
     * Goes over each key in the overwriteData object and checks if it is the same
     * if it is the same we delete it from the object. We also delete empty objects and arrays from the overwrite data
     */
    private static clearOverwriteDataCompareLoop(overwriteData: object, generalData: object) {
        for (const i in overwriteData) {
            // It's an object, continue looking
            if (typeof overwriteData[i] === 'object' && overwriteData[i] !== null) {
                if (generalData[i]) {
                    CreativeEditorEditHelpers.clearOverwriteDataCompareLoop(overwriteData[i], generalData[i]);
                }

                if (isEmpty(overwriteData[i])) {
                    delete overwriteData[i];
                }
            }
            // Empty array
            else if (Array.isArray(overwriteData[i]) && overwriteData[i].length === 0) {
                delete overwriteData[i];
            }
            // We have an array, compare those
            else if (
                Array.isArray(overwriteData[i]) &&
                generalData[i] &&
                Array.isArray(generalData[i]) &&
                isEqual(overwriteData[i].sort(), generalData[i].sort())
            ) {
                delete overwriteData[i];
            }
            // We compare the values
            else if (generalData[i] !== undefined && overwriteData[i] === generalData[i]) {
                delete overwriteData[i];
            }
        }
    }

    /**
     * This functions goes over each item in the interface setup array and their children to update the
     * blockmodel, block model parent and the linked block models
     * We also update the default value of the item if there are more than 1 selected formats because each format can have a different default value
     */
    static updateModels(
        interfaceSetupItems: InterfaceSetupInput[],
        selectedFormats: string[],
        blockModel?: string,
        linkedBlockModels: string[] = [],
        blockModelParent = ''
    ): InterfaceSetupInput[] {
        return interfaceSetupItems.map((item) => {
            const updatedItem: InterfaceSetupInput = { ...item };

            updatedItem.blockModel = blockModel;
            updatedItem.blockModelParent = blockModelParent;
            updatedItem.linkedBlockModels = linkedBlockModels;

            if (item.sourceDataModel) {
                if (item.sourceDataLocation === 'root') {
                    updatedItem.sourceDataModel = `${blockModel}.${item.sourceDataModel}`;
                } else {
                    updatedItem.sourceDataModel = `${blockModelParent}.gallery.${item.sourceDataModel}`;
                }
            }

            if (item.defaultValue && selectedFormats && selectedFormats.length > 0) {
                updatedItem.defaultValue = undefined;
            }

            if (item.items) {
                updatedItem.items = CreativeEditorEditHelpers.updateModels(item.items, selectedFormats, blockModel, linkedBlockModels, blockModelParent);
            }

            return updatedItem;
        });
    }

    /**
     * Set ups the overwrite data object. For each selected format we copy over the general data object to the overwrite position in
     * the template input data object. We do this to make sure that the inputs have data to show in their input as we are looking at the overwrite data place
     * instead of the general template input
     */
    static setupOverwriteData(localScopeId: string, creative: CreativeV2TemplateEnriched, selectedFormats: string[]): void {
        if (!creative.data?.templateInput) return;

        const templateInput = cloneDeep(creative.data.templateInput);

        const generalData = templateInput;

        // Copy and merge data for every element
        for (const format of selectedFormats) {
            const overwriteModel = 'overwrites.' + format;
            const overwriteData = get(generalData, overwriteModel);

            // Write data to the model
            let newData = {};
            merge(newData, generalData, overwriteData);
            newData = cloneDeep(newData);
            if (newData['overwrites']) {
                delete newData['overwrites'];
            }
            if (newData['gallery']) {
                delete newData['gallery'];
            }
            EditorData.setModel(BASE_BLOCK_MODEL + '.' + overwriteModel, newData, [], `scope-${localScopeId}`);
        }
    }
}

export { CreativeEditorEditHelpers };
